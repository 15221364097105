(function () {
  'use strict';

  angular
    .module('wbFaq')
    .config(config);

  function config($translateProvider) {
    $translateProvider.translations('hu', {
      faq: 'Gyakran ismételt kérdések',
      'faq.shortLabel': 'GYIK'
    });
  }
}());
